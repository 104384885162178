import { Box, Card, CardContent, Divider, Grid, Rating, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import QuoteSvg from './QuoteSvg';

const StyledRating = withStyles({
  iconFilled: {
    color: '#00aeee',
  },
})(Rating);

const Review = ({ body, name, value }) => {
  return (
    <Card sx={{ height: '100%', maxWidth: 400 }}>
      <CardContent sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <Box position="absolute">
          <QuoteSvg />
        </Box>
        <Box sx={{ mb: 1 }}>
          <StyledRating value={value} readOnly />
        </Box>
        <Box flex={1} display="flex" flexDirection="column">
          <Box flex={1}>
            <Typography variant="body2">{body}</Typography>
          </Box>
          <Box sx={{ my: 1 }}>
            <Divider />
          </Box>
          <Typography variant="caption">{name}</Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

const Reviews = () => {
  const { t } = useTranslation('landing');

  const reviews = [
    {
      body: t('reviews.review1'),
      name: 'Sara B.',
      value: 5,
    },
    {
      body: t('reviews.review2'),
      name: 'Ignacio A.',
      value: 5,
    },
    {
      body: t('reviews.review3'),
      name: 'Catalina S.',
      value: 5,
    },
    {
      body: t('reviews.review4'),
      name: 'Isabel G.',
      value: 5,
    },
  ];

  return (
    <Grid container spacing={3}>
      {reviews.map(({ name, value, body }) => (
        <Grid key={name} display="flex" justifyContent={'center'} item xs={12} sm={6} md={3}>
          <Review name={name} body={body} value={value} />
        </Grid>
      ))}
    </Grid>
  );
};

export default Reviews;
