import { mock } from '../lib/axios';

const agent = {
  overview: {
    recovered: {
      value: 39485.47,
      estimator: 'actualMonth',
    },
    dailyRecovered: {
      value: 3347.14,
      estimator: 'generalAverage',
    },
    comission: {
      value: 152.9,
      estimator: 'actualMonth',
    },
  },
  plans: {
    compliance: {
      value: 71,
      estimator: 'average',
      type: 'percentage',
    },
    averageAmount: {
      value: 37,
      estimator: 'general',
      type: 'currency',
    },
    active: [
      // last year? **
      {
        month: 1, // 'jan',
        value: 100,
      },
      {
        month: 2, // 'feb',
        value: 90,
      },
      {
        month: 3, // 'mar',
        value: 70,
      },
      {
        month: 4, // 'apr',
        value: 80,
      },
      {
        month: 5, // 'may',
        value: 110,
      },
      {
        month: 6, // 'jun',
        value: 98,
      },
      {
        month: 7, // 'jul',
        value: 105,
      },
    ],
    due: [
      {
        month: 1, // 'jan',
        value: 10,
      },
      {
        month: 2, // 'feb',
        value: 6,
      },
      {
        month: 3, // 'mar',
        value: 19,
      },
      {
        month: 4, // 'apr',
        value: 40,
      },
      {
        month: 5, // 'may',
        value: 20,
      },
      {
        month: 6, // 'jun',
        value: 10,
      },
      {
        month: 7, // 'jul',
        value: 28,
      },
    ],
    average: [
      {
        month: 1, // 'jan',
        value: 10,
      },
      {
        month: 2, // 'feb',
        value: 15,
      },
      {
        month: 3, // 'mar',
        value: 10,
      },
      {
        month: 4, // 'apr',
        value: 8,
      },
      {
        month: 5, // 'may',
        value: 20,
      },
      {
        month: 6, // 'jun',
        value: 17,
      },
      {
        month: 7, // 'jul',
        value: 15,
      },
    ],
  },
  plans_old: {
    active: {
      value: 4,
      estimator: 'actualMonth',
      status: 'right',
    },
    due: {
      value: 7,
      estimator: 'today',
    },
    dueDated: {
      value: 18,
      estimator: 'actualMonth',
    },
    daily: {
      value: 16,
      estimator: 'general',
    },
    compliance: {
      value: 71,
      estimator: 'average',
      type: 'percentage',
    },
    average: {
      value: 37,
      estimator: 'general',
      type: 'currency',
    },
  },
  comunication: {
    calls: { value: 7424, estimator: 'actualMonth' },
    minutes: { value: 997, estimator: 'today' },
    sms: { value: 188, estimator: 'actualMonth' },
    emails: { value: 5498, estimator: 'actualMonth' },
  },
  penalties: { value: -67, estimator: 'actualMonth', status: 'wrong' },
  chargebacks: { value: -374, estimator: 'general', status: 'wrong' },
};

mock.onGet('/api/agent/1').reply(200, { agent });
