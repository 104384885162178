import { useEffect } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Avatar, Box, Button, Divider, Drawer, Hidden, Typography } from '@material-ui/core';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import BankAccountIcon from '@material-ui/icons/AccountBalance';
import AssignmentReturnIcon from '@material-ui/icons/AssignmentReturn';
import BecomeMeIcon from '@material-ui/icons/PersonOutline';
import PaymentIcon from '@material-ui/icons/Payment';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import useAuth from '../../hooks/useAuth';
import ChartSquareBarIcon from '../../icons/ChartSquareBar';
import UserIcon from '../../icons/User';
import NavSection from '../NavSection';
import Scrollbar from '../Scrollbar';
import { ROLES } from 'src/constants';
import { hasPermission } from 'src/utils/auth';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

const sections = [
  {
    title: 'general',
    items: [
      {
        title: 'dashboard',
        path: '/cbo',
        icon: <ChartSquareBarIcon fontSize="small" />,
      },
      {
        title: 'account',
        path: '/cbo/account',
        icon: <UserIcon fontSize="small" />,
      },
    ],
  },
  {
    title: 'admin',
    roles: [ROLES.ADMIN, ROLES.SUPERVISOR],
    items: [
      {
        title: 'importLoans',
        path: '/import-loans',
        icon: <ImportExportIcon fontSize="small" />,
      },
      {
        title: 'importPayments',
        path: '/import-payments',
        icon: <BankAccountIcon fontSize="small" />,
      },
      {
        title: 'importChargebacks',
        path: '/import-chargebacks',
        roles: [ROLES.ADMIN],
        icon: <AssignmentReturnIcon fontSize="small" />,
      },
      {
        title: 'becomeMe',
        path: '/become-me',
        icon: <BecomeMeIcon fontSize="small" />,
      },
      {
        title: 'payments',
        path: '/payments',
        icon: <PaymentIcon fontSize="small" />,
      },
      {
        title: 'updatePhone',
        path: '/updatePhone',
        icon: <PhoneOutlinedIcon fontSize="small" />,
      },
    ],
  },
];

const DashboardSidebar = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const { user, logout } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { t } = useTranslation('common');

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Unable to logout', {
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
        variant: 'error',
      });
    }
  };

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              alignItems: 'center',
              backgroundColor: 'background.default',
              borderRadius: 1,
              display: 'flex',
              overflow: 'hidden',
              p: 2,
            }}
          >
            <RouterLink to="/cbo/account">
              <Avatar
                src={user.avatar}
                sx={{
                  cursor: 'pointer',
                  height: 48,
                  width: 48,
                }}
              />
            </RouterLink>
            <Box sx={{ ml: 2 }}>
              <Typography color="textPrimary" variant="subtitle2">
                {user.name}
              </Typography>
              <Typography color="textSecondary" variant="caption">
                {user.role}
              </Typography>
              <Box mt={1}>
                <Button color="primary" onClick={handleLogout} variant="text">
                  {t('logOut')}
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          {sections.map((section) => {
            if (!hasPermission(section.roles, user.role)) {
              return null;
            }
            return (
              <NavSection
                key={section.title}
                pathname={location.pathname}
                user={user}
                sx={{
                  '& + &': {
                    mt: 3,
                  },
                }}
                {...section}
              />
            );
          })}
        </Box>
        <Divider />
      </Scrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          PaperProps={{
            sx: {
              backgroundColor: 'background.paper',
              width: 280,
            },
          }}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          PaperProps={{
            sx: {
              backgroundColor: 'background.paper',
              height: 'calc(100% - 87px) !important',
              top: '87px !Important',
              width: 280,
            },
          }}
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default DashboardSidebar;
