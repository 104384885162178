import PropTypes from 'prop-types';
import { Box, Button, Paper } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const largeLogo = () => (
  <Box alt="Pagantis" component="img" src="/static/logo.svg" sx={{ mr: 1 }} />
);

const xxlLogo = ({ onClick, onClickLogin, actionText = '' }) => (
  <Box flex={1}>
    <Paper>
      <Box display="flex" flexDirection="row" alignItems="center" sx={{ mx: { lg: '5%' } }}>
        <Box flex={1}>
          <Box
            alt="Pagantis"
            component="img"
            maxWidth={'500px'}
            src="/static/logo.svg"
            onClick={onClick}
            sx={{
              ':hover': {
                cursor: 'pointer',
              },
              width: { xs: '70%', md: '30%' },
            }}
          />
        </Box>
        <Box sx={{ mr: 4, display: { xs: 'none', md: 'block' } }}>
          <Button variant="contained" onClick={onClickLogin}>
            {actionText}
          </Button>
        </Box>
      </Box>
    </Paper>
  </Box>
);

const Logo = ({ xxl }) => {
  const navigate = useNavigate();
  const { t } = useTranslation('landing');

  const goHome = () => {
    navigate('/');
  };

  const navigateLogin = () => {
    navigate('/login');
  };

  if (xxl) {
    return xxlLogo({ onClick: goHome, onClickLogin: navigateLogin, actionText: t('login_short') });
  }
  return largeLogo();
};

Logo.propTypes = {
  xxl: PropTypes.bool,
};

export default Logo;
