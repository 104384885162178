import firebase from 'src/lib/firebase';

const getAuth = () => {
  const auth = firebase.auth();

  return auth;
};

export const sendPaswordResetEmail = (email) => getAuth().sendPasswordResetEmail(email);

export const hasPermission = (sectionRoles, userRole) => {
  if (!sectionRoles || sectionRoles.length === 0 || sectionRoles.includes(userRole)) {
    return true;
  }
  return false;
};

export default getAuth;
