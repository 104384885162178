import { Suspense, lazy } from 'react';
import AuthGuard from './components/AuthGuard';
import DashboardLayout from './components/dashboard/DashboardLayout';
import GuestGuard from './components/GuestGuard';
import Landing from './components/landing/Landing';
import LandingLayout from './components/LandingLayout';
import LoadingScreen from './components/LoadingScreen';
import MainLayout from './components/MainLayout';
import { ROLES } from './constants';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// Authentication pages

const Login = Loadable(lazy(() => import('./pages/authentication/Login')));

// Dashboard pages

const Account = Loadable(lazy(() => import('./pages/dashboard/Account')));

// Admin pages

const ImportLoans = Loadable(lazy(() => import('./pages/admin/ImportLoans')));
const ImportPayments = Loadable(lazy(() => import('./pages/admin/ImportPayments')));
const ImportChargebacks = Loadable(lazy(() => import('./pages/admin/ImportChargebacks')));
const Payments = Loadable(lazy(() => import('./pages/admin/Payments')));
const BecomeMeScreen = Loadable(lazy(() => import('./pages/admin/BecomeMeScreen')));
const UpdatePhoneScreen = Loadable(lazy(() => import('./pages/admin/UpdatePhoneScreen')));

// Loans pages

const Loans = Loadable(lazy(() => import('./pages/loans/Loans')));
const Details = Loadable(lazy(() => import('./pages/loans/Details')));

// Payment pages

const PaymentResponse = Loadable(lazy(() => import('./pages/payments/PaymentResponse')));

// Error pages

const AuthorizationRequired = Loadable(lazy(() => import('./pages/AuthorizationRequired')));
const NotFound = Loadable(lazy(() => import('./pages/NotFound')));
const ServerError = Loadable(lazy(() => import('./pages/ServerError')));

// Legal pages
const TermsAndConditions = Loadable(lazy(() => import('./pages/legal/TermsAndConditions')));
const LegalAdvise = Loadable(lazy(() => import('./pages/legal/LegalAdvise')));
const PrivacyPolicy = Loadable(lazy(() => import('./pages/legal/PrivacyPolicy')));
const CookiesPolicy = Loadable(lazy(() => import('./pages/legal/CookiesPolicy')));
const GeneralConditions = Loadable(lazy(() => import('./pages/legal/GeneralConditions')));
const Psd2Policy = Loadable(lazy(() => import('./pages/legal/Psd2Policy')));

const routes = [
  {
    path: '/',
    element: (
      <LandingLayout>
        <Landing />
      </LandingLayout>
    ),
  },
  {
    path: 'login',
    element: (
      <GuestGuard>
        <MainLayout>
          <Login />
        </MainLayout>
      </GuestGuard>
    ),
  },
  // {
  //   path: 'authentication',
  //   children: [
  //     {
  //       path: 'login',
  //       element: (
  //         <GuestGuard>
  //           <Login />
  //         </GuestGuard>
  //       ),
  //     },
  //     {
  //       path: 'login-unguarded',
  //       element: <Login />,
  //     },
  //   ],
  // },
  {
    path: '/pago',
    children: [
      {
        path: 'ok',
        element: <PaymentResponse success />,
      },
      {
        path: 'error',
        element: <PaymentResponse />,
      },
    ],
  },
  {
    path: '*',
    element: <MainLayout />,
    children: [
      {
        path: '401',
        element: <AuthorizationRequired />,
      },
      {
        path: '404',
        element: <NotFound />,
      },
      {
        path: '500',
        element: <ServerError />,
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
  {
    path: 'cbo',
    element: (
      <MainLayout>
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      </MainLayout>
    ),
    children: [
      {
        index: true,
        element: <Loans />,
      },
      {
        path: 'account',
        element: <Account />,
      },
      {
        path: 'loans',
        children: [
          {
            index: true,
            element: <Loans />,
          },
          {
            path: ':loanId',
            element: <Details />,
          },
        ],
      },
    ],
  },
  // {
  //   path: 'account',
  //   element: (
  //     <MainLayout>
  //       <AuthGuard>
  //         <Account />
  //       </AuthGuard>
  //     </MainLayout>
  //   ),
  // },
  // {
  //   path: '/loans',
  //   element: (
  //     <MainLayout>
  //       <AuthGuard>
  //         <DashboardLayout />
  //       </AuthGuard>
  //     </MainLayout>
  //   ),
  //   children: [
  //     {
  //       index: true,
  //       element: <Loans />,
  //     },
  //     {
  //       path: ':loanId',
  //       element: <Details />,
  //     },
  //   ],
  // },
  {
    path: '/import-loans',
    element: (
      <MainLayout>
        <AuthGuard roles={[ROLES.ADMIN, ROLES.SUPERVISOR]}>
          <DashboardLayout />
        </AuthGuard>
      </MainLayout>
    ),
    children: [
      {
        index: true,
        element: <ImportLoans />,
      },
    ],
  },
  {
    path: '/become-me',
    element: (
      <MainLayout>
        <AuthGuard roles={[ROLES.ADMIN, ROLES.SUPERVISOR]}>
          <DashboardLayout />
        </AuthGuard>
      </MainLayout>
    ),
    children: [
      {
        index: true,
        element: <BecomeMeScreen />,
      },
    ],
  },
  {
    path: '/payments',
    element: (
      <MainLayout>
        <AuthGuard roles={[ROLES.ADMIN, ROLES.SUPERVISOR]}>
          <DashboardLayout />
        </AuthGuard>
      </MainLayout>
    ),
    children: [
      {
        index: true,
        element: <Payments />,
      },
    ],
  },
  {
    path: '/import-payments',
    element: (
      <MainLayout>
        <AuthGuard roles={[ROLES.ADMIN, ROLES.SUPERVISOR]}>
          <DashboardLayout />
        </AuthGuard>
      </MainLayout>
    ),
    children: [
      {
        index: true,
        element: <ImportPayments />,
      },
    ],
  },
  {
    path: '/import-chargebacks',
    element: (
      <MainLayout>
        <AuthGuard roles={[ROLES.ADMIN]}>
          <DashboardLayout />
        </AuthGuard>
      </MainLayout>
    ),
    children: [
      {
        index: true,
        element: <ImportChargebacks />,
      },
    ],
  },
  {
    path: '/updatePhone',
    element: (
      <MainLayout>
        <AuthGuard roles={[ROLES.ADMIN, ROLES.SUPERVISOR]}>
          <DashboardLayout />
        </AuthGuard>
      </MainLayout>
    ),
    children: [
      {
        index: true,
        element: <UpdatePhoneScreen />,
      },
    ],
  },
  {
    path: '/terminos-y-condiciones',
    element: (
      <LandingLayout>
        <TermsAndConditions />
      </LandingLayout>
    ),
  },
  {
    path: '/aviso-legal',
    element: (
      <LandingLayout>
        <LegalAdvise />
      </LandingLayout>
    ),
  },
  {
    path: '/politica-de-privacidad',
    element: (
      <LandingLayout>
        <PrivacyPolicy />
      </LandingLayout>
    ),
  },
  {
    path: '/politica-de-cookies',
    element: (
      <LandingLayout>
        <CookiesPolicy />
      </LandingLayout>
    ),
  },
  {
    path: '/condiciones-generales',
    element: (
      <LandingLayout>
        <GeneralConditions />
      </LandingLayout>
    ),
  },
  {
    path: '/psd2-sca',
    element: (
      <LandingLayout>
        <Psd2Policy />
      </LandingLayout>
    ),
  },
];

export default routes;
