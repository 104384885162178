export const THEMES = {
  LIGHT: 'LIGHT',
  DARK: 'DARK',
  NATURE: 'NATURE',
};

export const LOAN_STATUS = {
  ACTIVE: 'ACTIVE',
  DELINQUENT: 'DELINQUENT',
  CLOSED: 'CLOSED',
  PAYMENT_PROOF: 'PAYMENT_PROOF',
};

export const INSTALMENT_STATUS = {
  PENDING: 'PENDING',
  PAID: 'PAID',
  DELINQUENT: 'DELINQUENT',
  CANCELLED: 'CANCELLED',
  PAYMENT_PROOF: 'PAYMENT_PROOF',
};

export const PAYMENT_STATUS = {
  APPROVED: 'APPROVED',
  DECLINED: 'DECLINED',
  PENDING: 'PENDING',
  REFUNDED: 'REFUNDED',
  PAYMENT_PROOF: 'PAYMENT_PROOF',
  CHARGEBACK: 'CHARGEBACK',
};

export const PAYMENT_METHOD = {
  BANK_TRANSFER: 'bankTransfer',
  CARD: 'creditCard',
  PAYPAL: 'payPal',
};

export const PAYMENT_CHANNEL = {
  REGULAR_TRANSFER: 'regular_transfer',
};

export const PAYMENT_REQUESTER = {
  USER: 'user',
  TRANSFER: 'transfer',
};

export const PAYMENT_PLAN_STATUS = {
  ACTIVE: 'ACTIVE',
  DUE: 'DUE',
  CANCELLED: 'CANCELLED',
  COMPLETED: 'COMPLETED',
};

export const PAYMENT_PLAN_INSTALMENT_STATUS = {
  PAID: 'PAID',
  PENDING: 'PENDING',
  DUE: 'DUE',
  CONFIRMATION_PENDING: 'PENDING_OF_CONFIRMATION',
};

export const SMS_TYPES = {
  PAYMENT_LINK: 'PAYMENT_LINK',
  TRANSFER_DATA: 'TRANSFER_DATA',
};

export const ROLES = {
  ADMIN: 'ADMIN',
  SUPERVISOR: 'SUPERVISOR',
  RECOVERY_AGENT: 'RECOVERY_AGENT',
};

export const IMPORT_LOANS_STATUS = {
  LOADING: 'LOADING',
  IN_PROGRESS: 'IN_PROGRESS',
  READY: 'READY',
};

export const LOAN_DETAILS_MODAL_CONTENT = {
  PAYMENT_PLAN: 'PAYMENT_PLAN',
  PROOF_OF_PAYMENT: 'PROOF_OF_PAYMENT',
  INCIDENT: 'INCIDENT',
  TRANSFER_DATA: 'TRANSFER_DATA',
  PAYMENT_LINK: 'PAYMENT_LINK',
};

export const INCIDENT_STATUS = {
  ALL: 'ALL',
  OPENED: 'OPENED',
  RESOLVED: 'RESOLVED',
  DISMISSED: 'DISMISSED',
};

export const INCIDENT_PRIORITY = {
  ALL: 'ALL',
  LOW: 'LOW',
  MEDIUM: 'MEDIUM',
  HIGH: 'HIGH',
};

export const PENDING_PAYMENT_STATUS = {
  ALL: 'ALL',
  DISCARDED: 'DISCARDED',
  NOT_DISCARDED: 'NOT_DISCARDED',
};

export const COUNTRY_CODE = {
  ES: 'ES',
  IT: 'IT',
  FR: 'FR',
};

export const COUNTRY_PHONE_PREFIX = {
  ES: '+34',
  IT: '+39',
  FR: '+33',
};
