import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/functions';

import { firebaseConfig } from '../config';

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}
const firestore = firebase.firestore();
const auth = firebase.auth();
const storage = firebase.storage();
const functions = firebase.app().functions('europe-west2');

if (process.env.NODE_ENV === 'development' && typeof window !== 'undefined' && !window['_init']) {
  firestore.useEmulator('localhost', 8080);
  auth.useEmulator('http://localhost:9099');
  storage.useEmulator('localhost', 9199);
  functions.useEmulator('localhost', 5001);

  if (typeof window !== 'undefined') {
    window['_init'] = true;
  }
}

export default firebase;
