import { Box, Typography, withStyles } from '@material-ui/core';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const Section = ({ id, title, content, onClick, classes, selected }) => {
  return (
    <Box
      className={classes.root}
      onClick={() => onClick(id)}
      sx={{
        backgroundColor: selected ? 'rgba(0, 174, 238, 0.32)' : 'rgba(0, 174, 238, 0.12)',
        boxShadow: selected ? '#00aeee 0px 0px 0px 1px' : null,
      }}
    >
      <Typography variant="h6">{title}</Typography>
      <Typography variant="body2">{content}</Typography>
    </Box>
  );
};

const StyledSection = withStyles({
  root: {
    marginTop: 12,
    marginBottom: 12,
    backgroundColor: 'rgba(0, 174, 238, 0.12)',
    '&:hover': {
      backgroundColor: 'rgba(0, 174, 238, 0.32)',
      boxShadow: '#00aeee 0px 0px 0px 1px',
      cursor: 'pointer',
    },
    padding: 12,
    borderRadius: 6,
    transition:
      'background-color 0s ease 0s, box-shadow 225ms cubic-bezier(0, 0, 0.2, 1) 0ms, color 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
  },
})(Section);

const HowItWorks = () => {
  const [selectedStep, setSelectedStep] = useState(0);
  const { t } = useTranslation('landing');
  const steps = [
    {
      title: t('howItWorks.firstStepTitle'),
      content: t('howItWorks.firstStep'),
      imagePath: '/static/screenshots/login.png',
    },
    {
      title: t('howItWorks.secondStepTitle'),
      content: t('howItWorks.secondStep'),
      imagePath: '/static/screenshots/loans.png',
    },
    {
      title: t('howItWorks.thirdStepTitle'),
      content: t('howItWorks.thirdStep'),
      imagePath: '/static/screenshots/payment.png',
    },
  ];

  const handleSectionClick = (index) => {
    setSelectedStep(index);
  };

  return (
    <Box>
      <Typography variant="h5">{t('howItWorks.title')}</Typography>
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}>
        <Box flex="1" sx={{ mr: { xs: 0, md: 6 }, maxWidth: 600 }}>
          {steps.map((step, index) => (
            <StyledSection
              key={index}
              id={index}
              title={step.title}
              content={step.content}
              onClick={handleSectionClick}
              selected={selectedStep === index}
            />
          ))}
        </Box>

        {false && (
          <Box
            alt="Explanation"
            component="img"
            maxWidth={'300px'}
            src={steps[selectedStep].imagePath}
            sx={{
              width: { xs: '100%' },
              boxShadow: '4px 14px 30px 0px rgba(0,0,0,0.4)',
              borderRadius: 3,
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default HowItWorks;
