import PropTypes from 'prop-types';
import { AppBar, Box, Container, Divider, Toolbar } from '@material-ui/core';
import Logo from 'src/components/Logo';

const MainNavbar = (props) => {
  return (
    <AppBar
      elevation={0}
      sx={{
        backgroundColor: 'background.secondary',
        color: 'text.secondary',
      }}
    >
      <Toolbar>
        <Container maxWidth="sm">
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Logo />
          </Box>
        </Container>
      </Toolbar>
      <Divider />
    </AppBar>
  );
};

MainNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func,
};

export default MainNavbar;
