import { Box, Typography, IconButton } from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';

const StaticFooter = () => {
  return (
    <Box
      position="fixed"
      bottom="0"
      width="100%"
      sx={{
        backgroundColor: 'background.secondary',
      }}
      textAlign="center"
    >
      <Typography variant="caption" m={1}>
        Paseo de la Castellana, 95. 28046, Madrid, Madrid
      </Typography>
      <Box display={{ xs: 'block', lg: 'inline-block' }}>
        <IconButton size="small" color="primary" href="mailto:support@pagantis.eu">
          <EmailIcon fontSize="small" />
          <Typography variant="caption">support@pagantis.eu</Typography>
        </IconButton>
      </Box>
      <Box display={{ xs: 'block', lg: 'inline-block' }}>
        <IconButton size="small" color="primary" href="tel:">
          <PhoneIcon fontSize="small" />
          <Typography variant="caption"></Typography>
        </IconButton>
      </Box>
    </Box>
  );
};

export default StaticFooter;
