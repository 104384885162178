import { add, format, isAfter, parse, sub, setMonth, addYears } from 'date-fns';
import endOfDay from 'date-fns/endOfDay';
import { es, enGB } from 'date-fns/locale';
import startOfDay from 'date-fns/startOfDay';
import { zeroPad } from './utils';

export const dateFormat = 'dd/MM/yyyy';
export const dateTimeFormat = 'dd/MM/yyyy HH:mm';
export const dateTimeWithSecodsFormat = 'dd/MM/yyyy HH:mm:ss';

export const today = format(new Date(), dateFormat);

export const isSameDay = (dateL, dateR) => formatDate(dateL) === formatDate(dateR);

export const isAfterDate = (dateL, dateR) => isAfter(dateL, dateR);

export const formatDate = (date, df = dateFormat) => format(new Date(date), df);

export const formatDateTime = (date, df = dateTimeFormat) => format(new Date(date), df);

export const parseDate = (date, format = dateFormat) => parse(date, format, new Date());

export const addMonthsToDate = (date, months) => add(date, { months });

export const dayStart = (date) => startOfDay(date);

export const dayEnd = (date) => endOfDay(date);

export const dayLapseFromDate = (date) => {
  const d = new Date(date);
  return {
    startDate: startOfDay(d),
    endDate: endOfDay(d),
  };
};

export const now = () => new Date();

export const previousMonths = (monthsRequired = 11) => {
  const months = [];
  for (let i = 0; i < monthsRequired - 1; i++) {
    const month = sub(new Date(), { months: i });
    months.push({
      short: format(month, 'MM/yyyy'),
      monthName: format(month, 'MMM'),
    });
  }
  return months;
};

export const nextYears = (years) => {
  return Array.from(Array(years).keys()).map((it) => {
    const d = addYears(new Date(), it);
    const year = d.getFullYear().toString();
    return { short: year.substr(2, 4), long: year };
  });
};

export const monthNames = (locale = 'es') => {
  const finalLocale = locale === 'es' ? es : enGB;
  return Array.from(Array(12).keys()).map((month) => {
    const d = setMonth(new Date(), month);
    const monthValue = zeroPad(month + 1, 2);
    return {
      value: monthValue,
      text: `${monthValue} - ${format(d, 'LLLL', { locale: finalLocale })}`,
    };
  });
};
