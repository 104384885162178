import { Box, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const Merchants = () => {
  const { t } = useTranslation('landing');
  return (
    <Box>
      <Box textAlign={'center'}>
        <Typography variant="h6">{t('merchantsTitle')}</Typography>
      </Box>
      <Box
        sx={{
          display: { xs: 'flex', md: 'none' },
          gap: 2,
          py: 1,
          overflow: 'auto',
          width: 343,
          scrollSnapType: 'x mandatory',
          '& > *': {
            scrollSnapAlign: 'center',
          },
          '::-webkit-scrollbar': { display: 'none' },
          alignItems: 'center',
        }}
      >
        <Grid item xs={3} sx={{ minWidth: 'fit-content' }}>
          <img src="/static/merchants/primor.png" alt="Primor" width={120} />
        </Grid>
        <Grid item xs={3} sx={{ minWidth: 'fit-content' }}>
          <img src="/static/merchants/deporvillage.png" alt="Deporvillage" width={120} />
        </Grid>
        <Grid item xs={3} sx={{ minWidth: 'fit-content' }}>
          <img src="/static/merchants/vestiaire.png" alt="Vestiaire Collection" width={120} />
        </Grid>
        <Grid item xs={3} sx={{ minWidth: 'fit-content' }}>
          <img src="/static/merchants/fitnessdigital.webp" alt="Fitness Digital" width={120} />
        </Grid>
      </Box>
      <Grid
        sx={{ display: { xs: 'none', md: 'flex' } }}
        container
        spacing={3}
        alignItems="center"
        justifyContent={'center'}
      >
        <Grid item>
          <img src="/static/merchants/primor.png" alt="Primor" width={120} />
        </Grid>
        <Grid item>
          <img src="/static/merchants/deporvillage.png" alt="Deporvillage" width={120} />
        </Grid>
        <Grid item>
          <img src="/static/merchants/vestiaire.png" alt="Vestiaire Collection" width={120} />
        </Grid>
        <Grid item>
          <img src="/static/merchants/fitnessdigital.webp" alt="Fitness Digital" width={120} />
        </Grid>
      </Grid>
      <Box></Box>
    </Box>
  );
};

export default Merchants;
