import { Box, Button, Typography } from '@material-ui/core';
import Phone from '@material-ui/icons/Phone';
import Email from '@material-ui/icons/Email';
import { useTranslation } from 'react-i18next';

const Help = () => {
  const { t } = useTranslation('landing');

  const sendEmail = () => {
    window.open('mailto:support@pagantis.eu');
  };

  const phoneCall = () => {
    window.open('tel:34913247408');
  };

  return (
    <Box
      sx={{
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center top',
        backgroundColor: 'black',
        p: 6,
      }}
    >
      <Typography variant="h3" color={'white'} align="center">
        {t('help.title')}
      </Typography>
      <Typography variant="body1" color="white" align="center" sx={{ my: 3 }}>
        {t('help.subtitle')}
      </Typography>
      <Box display={'flex'} justifyContent="center">
        <Button variant="text" startIcon={<Email />} onClick={sendEmail}>
          support@pagantis.eu
        </Button>
      </Box>
      <Box display={'flex'} justifyContent="center">
        <Button variant="text" startIcon={<Phone />} onClick={phoneCall}>
          +34 913 247 408
        </Button>
      </Box>
    </Box>
  );
};

export default Help;
