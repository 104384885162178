import React from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import Reviews from './Reviews';
import Help from './Help';
import Merchants from './Merchants';
import { HelpOutline } from '@material-ui/icons';
import Faq from './Faq';
import HowItWorks from './HowItWorks';
import { useTranslation } from 'react-i18next';

const Landing = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('landing');

  const navigateToAuth = () => {
    navigate('/login');
  };

  const handleGoToFaq = () => {
    const faqSection = document.querySelector('#faq');
    faqSection.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Box
      flex="1 1 auto"
      sx={{
        backgroundImage: 'url("/static/backgrounds/gradient-bg.svg")',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Helmet>
        <title>Pagantis</title>
      </Helmet>
      <Box
        sx={{
          py: '80px',
          px: { xs: '20px', lg: '5%' },
        }}
      >
        <Box display="flex" sx={{ flexDirection: 'column' }}>
          <Box sx={{ my: 4 }}>
            <Typography variant="h6">{t('mainTitle')}</Typography>
            <Typography variant="h4" sx={{ maxWidth: { xs: '100%', md: 600 } }}>
              {t('introDescription')}
            </Typography>
          </Box>
          <Box flex={1} display={'flex'} justifyContent="start">
            <Button size="large" variant="contained" onClick={navigateToAuth} sx={{ mr: 2 }}>
              {t('goToMyAccount')}
            </Button>
            <Button startIcon={<HelpOutline />} onClick={handleGoToFaq}>
              {t('questionsLink')}
            </Button>
          </Box>
        </Box>
      </Box>
      <Box sx={{ mx: { xs: 4, lg: '5%' }, my: 4 }}>
        <HowItWorks />
      </Box>
      <Box sx={{ m: 4, mt: 8 }}>
        <Merchants />
      </Box>
      <Box sx={{ mx: { xs: 4, lg: '5%' }, my: 4 }}>
        <Reviews />
      </Box>
      <Box sx={{ mx: { xs: 4, lg: '5%' }, my: 4 }}>
        <Faq />
      </Box>
      <Help />
    </Box>
  );
};

Landing.propTypes = {};

export default Landing;
