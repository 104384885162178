import PropTypes from 'prop-types';
import { experimentalStyled } from '@material-ui/core/styles';
import Footer from './Footer';
import Logo from './Logo';
import { Box } from '@material-ui/core';

const LandingLayoutRoot = experimentalStyled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
}));

const LandingLayout = ({ children }) => {
  return (
    <LandingLayoutRoot>
      <Box>
        <Box display={'flex'} justifyContent={'center'}>
          <Logo xxl />
        </Box>
        {children}
        <Footer />
      </Box>
    </LandingLayoutRoot>
  );
};

LandingLayout.propTypes = {
  children: PropTypes.node,
};

export default LandingLayout;
