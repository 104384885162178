export const sanitizeText = (text) => {
  return text
    .trim()
    .normalize('NFD')
    .replace(/\p{Diacritic}/gu, '')
    .toLowerCase();
};

export const shuffleName = (name) => {
  const names = [];
  const itms = name.split(' ');
  for (let i = 0; i < itms.length; i++) {
    names.push(buildName(itms, i));
  }
  itms.splice(1, 1);
  for (let i = 0; i < itms.length; i++) {
    names.push(buildName(itms, i));
  }

  return names.slice(0, 9);
};

const buildName = (itms, idx) => {
  let n = '';
  let j = idx;
  let cont = 0;
  while (cont < itms.length) {
    n = n.concat(`${itms[j]} `);
    j = j + 1 >= itms.length ? 0 : j + 1;
    cont++;
  }
  return sanitizeText(n);
};
