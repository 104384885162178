import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

const LoadingScreen = ({ label }) => {
  return (
    <Box
      sx={{
        width: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress size={100} />
      {label && (
        <Typography color="textSecondary" variant="caption">
          {label}
        </Typography>
      )}
    </Box>
  );
};
LoadingScreen.propTypes = {
  label: PropTypes.string,
};

export default LoadingScreen;
