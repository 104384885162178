import { combineReducers } from '@reduxjs/toolkit';

import { reducer as OverviewReducer } from '../slices/overview';
import { reducer as LoanReducer } from '../slices/loan';
import { reducer as ImportReducer } from '../slices/import';
import { reducer as CustomerReducer } from '../slices/customer';
import { reducer as PendingPaymentsReducer } from '../slices/pendingPayments';
import { reducer as BankAccountsReducer } from '../slices/bankAccount';

const rootReducer = combineReducers({
  overview: OverviewReducer,
  loans: LoanReducer,
  import: ImportReducer,
  customer: CustomerReducer,
  pendingPayments: PendingPaymentsReducer,
  bankAccounts: BankAccountsReducer,
});

export default rootReducer;
