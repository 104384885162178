import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AppBar, Box, Hidden, IconButton, Toolbar } from '@material-ui/core';
import { experimentalStyled } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import MenuIcon from '../../icons/Menu';
import Logo from '../Logo';

const DashboardNavbarRoot = experimentalStyled(AppBar)(({ theme }) => ({
  ...{
    backgroundColor: theme.palette.background.secondary,
    boxShadow: 'none',
    color: theme.palette.primary.contrastText,
  },
  zIndex: theme.zIndex.drawer + 100,
}));

const DashboardNavbar = (props) => {
  const { onSidebarMobileOpen, ...other } = props;

  return (
    <DashboardNavbarRoot {...other}>
      <Toolbar sx={{ minHeight: 87 }}>
        <Container maxWidth="sm">
          <Box display="flex">
            <Hidden lgUp>
              <IconButton color="primary" onClick={onSidebarMobileOpen}>
                <MenuIcon fontSize="medium" />
              </IconButton>
            </Hidden>
            <Box
              flex={1}
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <RouterLink to="/">
                <Logo
                  sx={{
                    height: 40,
                    width: 40,
                  }}
                />
              </RouterLink>
            </Box>
          </Box>
        </Container>
      </Toolbar>
    </DashboardNavbarRoot>
  );
};

DashboardNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func,
};

export default DashboardNavbar;
