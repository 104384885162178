export const zeroPad = (num, places) => String(num).padStart(places, '0');

export const decimalToCurrencyInCents = (decimalAmount) => {
  try {
    return parseInt((decimalAmount * 100).toFixed(0));
  } catch (e) {
    console.error(`Error parsing amount ${decimalAmount}`, e);
    return null;
  }
};
