import { Box, Link } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const Footer = (props) => {
  const { t } = useTranslation('footer');

  const sections = [
    {
      title: t('termsAndConditions'),
      href: '/terminos-y-condiciones',
    },
    {
      title: t('legalAdvise'),
      href: '/aviso-legal',
    },
    {
      title: t('privacyPolicy'),
      href: '/politica-de-privacidad',
    },
    {
      title: t('cookiesPolicy'),
      href: '/politica-de-cookies',
    },
  ];

  return (
    <Box
      sx={{
        backgroundColor: 'background.secondary',
        pb: 6,
        pt: 4,
      }}
      {...props}
    >
      <Box display="flex" justifyContent="center">
        {sections.map((section) => (
          <Box key={section.title} m={2}>
            <Link key={section.title} variant="body2" href={section.href}>
              {section.title}
            </Link>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Footer;
