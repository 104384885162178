import { isArray } from 'lodash';

export const dateParser = (obj) => {
  Object.entries(obj).forEach(([k, v], i) => {
    if (v && typeof v === 'object' && Object.keys(v).join('|') === 'seconds|nanoseconds') {
      obj[k] = v.toDate().toISOString();
    }
    if (v && typeof v === 'object') {
      obj[k] = dateParser(obj[k]);
    }
    if (v && isArray(v)) {
      obj[k] = v.map(dateParser);
    }
  });
  return obj;
};
