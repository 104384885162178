import { createSlice } from '@reduxjs/toolkit';
import { LOAN_STATUS } from 'src/constants';
import getFirestore from 'src/utils/firestore';
import { dateParser } from './parser';

const initialState = {
  customer: null,
  customerDeleted: false,
};

const slice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    setCustomer(state, action) {
      state.customer = action.payload;
    },
    setCustomerDeleted(state, action) {
      state.customerDeleted = action.payload;
    },
  },
});

export const { reducer } = slice;

export const setCustomer = (customerData) => async (dispatch) => {
  return dispatch(slice.actions.setCustomer(customerData));
};

export const fetchCustomer = (userId) => async (dispatch) => {
  try {
    const querySnapshot = await getFirestore()
      .collection('customers')
      .doc(userId)
      .withConverter(customerConverter)
      .get();
    if (!querySnapshot.exists) {
      throw new Error(`Error retireving user ${userId}`);
    }
    const data = querySnapshot.data();

    const canDeleteAccount = await allUserLoansClosed(data.id);

    const customer = {
      ...data,
      canDeleteAccount,
    };

    dispatch(slice.actions.setCustomer(customer));
  } catch (e) {
    console.error(e);
    dispatch(slice.actions.setCustomer(null));
  }
};

export const isCustomerDeleted = (userId) => async (dispatch) => {
  try {
    const querySnapshot = await getFirestore().collection('customers').doc(userId).get();

    if (!querySnapshot.exists) {
      throw new Error(`Error retireving user ${userId}`);
    }
    const data = querySnapshot.data();

    dispatch(slice.actions.setCustomerDeleted(data.deleted));
  } catch (e) {
    console.error(e);
    dispatch(slice.actions.setCustomerDeleted(false));
  }
};

const allUserLoansClosed = async (userId) => {
  try {
    const querySnapshot = await getFirestore()
      .collection('records')
      .where('user', '==', userId)
      .where('status', '==', LOAN_STATUS.DELINQUENT)
      .get();
    return querySnapshot.size === 0;
  } catch (e) {
    console.error(e);
    return false;
  }
};

export const customerConverter = {
  toFirestore(req) {
    return req;
  },
  fromFirestore(snapshot, options) {
    const data = snapshot.data(options);

    return dateParser(data);
  },
};
