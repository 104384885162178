import { INSTALMENT_STATUS, PAYMENT_METHOD, PAYMENT_STATUS } from 'src/constants';
import { isAfterDate } from 'src/utils/dateUtils';
import { dateParser } from './parser';

export const loanConverter = {
  toFirestore(req) {
    return req;
  },
  fromFirestore(snapshot, options) {
    const data = snapshot.data(options);

    data.totalPayed = data.payments
      ? data.payments
          .filter((it) => it.status === PAYMENT_STATUS.APPROVED)
          .map((it) => +it.amount)
          .reduce((a, b) => a + b, 0)
      : 0;

    data.payments.forEach((it) => {
      const callback =
        it?.callbacks?.find((c) => c.TransactionName === 'Autorización') || it.callback;

      if (it.status === PAYMENT_STATUS.REFUNDED) {
        it.refundAmount = +it?.callbacks?.find((c) => c.TransactionName === 'Devolución').Amount;
      }

      if (callback) {
        it.authCode = callback.AuthCode;
        it.currency = callback.Currency;
        it.originalIp = '127.0.0.1';
        it.terminal = callback.TpvID;
      }
      it.isRefundable =
        it.status === PAYMENT_STATUS.APPROVED &&
        it.paymentMethod === PAYMENT_METHOD.CARD &&
        callback?.Signature !== 'not_present' &&
        callback?.IdUser &&
        callback?.TokenUser;
      delete it.callbacks;
    });

    data.payments.sort((a, b) =>
      isAfterDate(a?.confimedAt?.toDate(), b?.confimedAt?.toDate()) ? 1 : -1
    );

    return dateParser(data);
  },
};

export const paymentLoanConverter = {
  toFirestore(req) {
    return req;
  },
  fromFirestore(snapshot, options) {
    // TODO review the calculations of this converter
    const data = snapshot.data(options);

    const hasPendingPayment = data.payments.some((it) => it.status === PAYMENT_STATUS.PENDING);

    const amortized = data.payments
      .filter((it) => it.status === PAYMENT_STATUS.APPROVED)
      .map((it) => it.amount)
      .reduce((a, b) => a + b, 0);

    const totalDebt = data.amount + data.interests + data.delinquentFees - amortized;

    return {
      entityName: data.entityName,
      amount: data.amount,
      interests: data.interests,
      penalties: data.delinquentFees,
      amortized: -amortized,
      totalDebt,
      userName: data.customer.fullName || '',
      phoneNumber: data.customer.phoneNumbers[0] || '',
      dni: data.customer.dni,
      agent: data.agents[data.agents.length - 1],
      hasPendingPayment,
      paymentMethods: data.paymentMethods,
    };
  },
};

export const recordSimplfiedConverter = {
  toFirestore(req) {
    return req;
  },
  fromFirestore(snapshot, options) {
    const data = snapshot.data(options);

    const pendingInstalments = data.instalments.filter((it) =>
      [INSTALMENT_STATUS.DELINQUENT, INSTALMENT_STATUS.PENDING].includes(it.status)
    );

    const pendingAmount = pendingInstalments
      .map((it) => it.remaining + (it.delinquentFees || 0))
      .reduce((a, b) => a + b, 0);
    return {
      id: snapshot.id,
      instalmentAmount: data.instalments[0].amount,
      pendingInstalments: pendingInstalments.length,
      pendingAmount,
      merchant: data.description,
    };
  },
};
