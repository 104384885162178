import { createSlice } from '@reduxjs/toolkit';
import { getDaysInMonth, parse } from 'date-fns';
import { previousMonths } from 'src/utils/dateUtils';
import getFirestore from 'src/utils/firestore';
import { overviewConverter } from './overviewConverter';

const initialState = {
  overview: null,
};

const slice = createSlice({
  name: 'overview',
  initialState,
  reducers: {
    getOverview(state, action) {
      state.overview = action.payload;
    },
  },
});

export const { reducer } = slice;

const fetchOverview = (uid) => {
  return getFirestore()
    .collection('records')
    .where('agents', 'array-contains', uid)
    .withConverter(overviewConverter);
};

export const getOverview = (uid) => async (dispatch) => {
  return fetchOverview(uid).onSnapshot((querySnapshot) => {
    let recovered = 0;
    let paymentPlansOK = 0;
    let paymentPlansNOK = 0;
    let fee = 0;
    let activePlans = {};
    let duePlans = {};
    let averagePlans = {};

    previousMonths().forEach((it) => {
      activePlans[it.short] = { month: it.monthName, value: 0 };
      duePlans[it.short] = { month: it.monthName, value: 0 };
      averagePlans[it.short] = { month: it.monthName, value: 0 };
    });

    querySnapshot.forEach((doc) => {
      const data = doc.data();
      recovered += data.recovered;
      paymentPlansOK += data.completedPaymentPlans;
      paymentPlansNOK += data.notCompletedPaymentPlans;
      fee += data.fee;
      Object.keys(data.activePlans).forEach((k) => {
        if (activePlans[k]) {
          activePlans[k].value += data.activePlans[k].value;
        }
        if (duePlans[k]) {
          duePlans[k].value += data.activePlans[k].due ? 1 : 0;
        }
      });
    });

    Object.keys(averagePlans).forEach((it) => {
      if (averagePlans[it]) {
        const planDate = parse(it, 'MM/yyyy', new Date());
        averagePlans[it].value = +(activePlans[it].value / getDaysInMonth(planDate)).toFixed(2);
      }
    });

    const aux = {
      overview: {
        recovered: {
          value: recovered,
          estimator: 'actualMonth',
        },
        dailyRecovered: {
          value: recovered / 365,
          estimator: 'generalAverage',
        },
        comission: {
          value: 0,
          estimator: 'actualMonth',
        },
      },
      plans: {
        compliance: {
          value: +((paymentPlansOK / (paymentPlansOK + paymentPlansNOK)) * 100 || 0).toFixed(2),
          estimator: 'average',
          type: 'percentage',
        },
        averageAmount: {
          value: fee / (paymentPlansOK + paymentPlansNOK) || 0,
          estimator: 'general',
          type: 'currency',
        },
        active: activePlans,
        due: duePlans,
        average: averagePlans,
      },
      // update this when we have some data example
      comunication: {
        calls: { value: 7424, estimator: 'actualMonth' },
        minutes: { value: 997, estimator: 'today' },
        sms: { value: 188, estimator: 'actualMonth' },
        emails: { value: 5498, estimator: 'actualMonth' },
      },
      penalties: { value: -67, estimator: 'actualMonth', status: 'wrong' },
      chargebacks: { value: -374, estimator: 'general', status: 'wrong' },
    };

    dispatch(slice.actions.getOverview(aux));
  });
};
