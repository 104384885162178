import { createSlice } from '@reduxjs/toolkit';
import { FireSQL } from 'firesql';
import { loanConverter, paymentLoanConverter, recordSimplfiedConverter } from './loanConverter';
import getFirestore from 'src/utils/firestore';
import { isIdentityDocument } from 'src/utils/validators';
import { LOAN_STATUS } from 'src/constants';
import { sanitizeText, shuffleName } from 'src/utils/stringUtils';

const initialState = {
  loan: null,
  agents: null,
  error: null,
};

const slice = createSlice({
  name: 'loan',
  initialState,
  reducers: {
    setAgents(state, action) {
      state.agents = action?.payload || null;
    },
    getLoan(state, action) {
      if (action.payload) {
        state.loan = action.payload;
        state.error = false;
      } else {
        state.loan = null;
        state.error = true;
      }
    },
  },
});

export const { reducer } = slice;

export const getLoan = (ref_id) => async (dispatch) => {
  getFirestore()
    .collection('records')
    .doc(ref_id)
    .withConverter(paymentLoanConverter)
    .get()
    .then((data) => {
      dispatch(slice.actions.getLoan(data.data()));
    })
    .catch(console.error);
};

export const getLoanDetails = (loanId) => async (dispatch) => {
  return getFirestore()
    .collection('records')
    .withConverter(loanConverter)
    .doc(loanId)
    .onSnapshot(
      (doc) => {
        const loan = doc.data();
        dispatch(slice.actions.getLoan(loan));
      },
      (err) => {
        console.error(err);
      },
      () => {}
    );
};

export const searchLoans = (filters) => async () => {
  const fireSQL = new FireSQL(getFirestore());
  const query = `SELECT __name__ AS id, refId, \`customer.dni\` AS dni, \`customer.fullName\` AS fullName, entityName as entity,  status  FROM records WHERE \`${filters.field}\` LIKE '${filters.value}%'`;

  return fireSQL.query(query, { includeId: true });
};

export const getLoansByCustomer = async (customerRef) => {
  try {
    let baseQuery = getFirestore().collection('customers');
    if (isIdentityDocument(customerRef)) {
      baseQuery = baseQuery.where('dni', '==', customerRef.toUpperCase());
    } else {
      const name = sanitizeText(customerRef);
      const names = shuffleName(name);

      baseQuery = baseQuery.where('fullName_lowercase', 'in', names);
    }

    const userDoc = await baseQuery.get();

    if (userDoc.empty) {
      // eslint-disable-next-line no-throw-literal
      throw { code: 'no-user', message: `Customer not found for ref ${customerRef}` };
    }
    const user = userDoc.docs[0].data().id;

    const recordsDoc = await getFirestore()
      .collection('records')
      .where('user', '==', user)
      .where('status', '!=', LOAN_STATUS.CLOSED)
      .withConverter(recordSimplfiedConverter)
      .get();

    if (recordsDoc.empty) {
      // eslint-disable-next-line no-throw-literal
      throw { code: 'no-records', message: `There is no records for this user ${customerRef}` };
    }

    return recordsDoc.docs.map((it) => it.data());
  } catch (e) {
    console.error(e);
    console.error('Error getting loans for customer', e.message);
    throw e.code;
  }
};
