import { parse, isDate } from 'date-fns';
import { dateFormat } from './dateUtils';

export const validateDate = (date, format = dateFormat) =>
  isDate(date) ? date : parse(date, format, new Date());

export const isIdentityDocument = (value) => DNIorNIE(value) || CodiceFiscale(value);

export const DNIorNIE = (value) => {
  const AvgDNIRegex = /^\d{8}[-]?[a-zA-Z]$/;
  const NIERegex = /^[xyzXYZ][- ]?[0-9]{7}[- ]?[a-zA-Z]$/;
  return AvgDNIRegex.test(value) || NIERegex.test(value);
};

export const CodiceFiscale = (value) => {
  const CodiceFiscaleRegex = /^([A-Z]{6}[0-9LMNPQRSTUV]{2}[ABCDEHLMPRST]{1}[0-9LMNPQRSTUV]{2}[A-Z]{1}[0-9LMNPQRSTUV]{3}[A-Z]{1})$|([0-9]{11})$/gm;
  return CodiceFiscaleRegex.test(value);
};
