import { mock } from '../lib/axios';

const plansDueToday = [
  {
    id: 'rnd_id_1',
    dni: '20567978K',
    name: 'SHEILA GALLEGO BALLESTER',
    fee: 9,
    phone: '123123123',
  },
  {
    id: 'rnd_id_2',
    dni: '42949375A',
    name: 'ADRIA PLA PRIETO',
    fee: 56,
    phone: '124124124',
  },
  {
    id: 'rnd_id_3',
    dni: '18373054X',
    name: 'EMMA GONZALO VICENTE',
    fee: 96,
    phone: '234546634',
  },
];

const plansDueDated = [
  {
    id: 'rnd_id_4',
    dni: '01940268B',
    name: 'JUAN IGNACIO VALLE GUZMAN',
    fee: 95,
    phone: '123123123',
  },
  {
    id: 'rnd_id_5',
    dni: '22882470T',
    name: 'SERGIO DOMENECH GODOY',
    fee: 63,
    phone: '124124124',
  },
  {
    id: 'rnd_id_6',
    dni: '65576701K',
    name: 'MARIANO LINARES SEGURA',
    fee: 76,
    phone: '234546634',
  },
];

mock.onPost('/api/plans').reply((request) => {
  if (Object.keys(JSON.parse(request.data)).length !== 0) {
    return [200, plansDueDated];
  }
  return [200, plansDueToday];
});
