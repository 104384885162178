import { Suspense, useEffect } from 'react';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider, StyledEngineProvider } from '@material-ui/core';
import './i18n';
import GlobalStyles from './components/GlobalStyles';
import RTL from './components/RTL';

import SplashScreen from './components/SplashScreen';
import { gtmConfig } from './config';
import useAuth from './hooks/useAuth';
import useSettings from './hooks/useSettings';
import gtm from './lib/gtm';
import routes from './routes';
import { createTheme } from './theme';
import { useTranslation } from 'react-i18next';
import * as rollbar from './lib/rollbar';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

const App = () => {
  const { i18n } = useTranslation();
  const router = createBrowserRouter(routes);

  const { settings } = useSettings();
  const auth = useAuth();

  useEffect(() => {
    gtm.initialize(gtmConfig);
    rollbar.ignoreChunkMessages();
  }, []);

  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme,
    language: i18n.language,
  });

  return (
    <Suspense fallback={<></>}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <RTL direction={settings.direction}>
            <SnackbarProvider dense maxSnack={3}>
              <GlobalStyles />
              {auth.isInitialized ? <RouterProvider router={router} /> : <SplashScreen />}
            </SnackbarProvider>
          </RTL>
        </ThemeProvider>
      </StyledEngineProvider>
    </Suspense>
  );
};

export default App;
