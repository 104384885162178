import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

const Question = ({ question, answer }) => {
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography>{question}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>{answer}</Typography>
      </AccordionDetails>
    </Accordion>
  );
};

const Faq = () => {
  const { t } = useTranslation('landing');

  const questions = [
    {
      question: t('faqs.question1'),
      answer: t('faqs.answer1'),
    },
    {
      question: t('faqs.question2'),
      answer: t('faqs.answer2'),
    },
    {
      question: t('faqs.question3'),
      answer: t('faqs.answer3'),
    },
  ];

  return (
    <Box id="faq">
      <Typography variant="h5" sx={{ mb: 2 }}>
        {t('faqTitle')}
      </Typography>
      {questions.map(({ question, answer }) => (
        <Question key={question} question={question} answer={answer} />
      ))}
    </Box>
  );
};

export default Faq;
